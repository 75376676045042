import { useEffect } from "react";
import SearchOff from "../components/SearchOff";

const GrafikNow = () => {

    useEffect(()=>{
        window.scrollTo(0,0);
      }, [])

    return (
        <section className="power-off">
            <div className="container">
                <div className="content" data-aos="fade-up">
                    <SearchOff type={1} />
                </div> 
            </div>
        </section> 
    )
}

export default GrafikNow