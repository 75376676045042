import './styles/global.scss';
import './styles/style.scss';
import './styles/header.scss';
import './styles/footer.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter} from "react-router-dom";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Layout from './pages/Layout';
import OkeyHttp from './pages/OkeyHttp';
import Grafik from './pages/Grafik';
import GroupPage from './pages/GroupPage';
import Discrepancy from './pages/Discrepancy';
// import GrafikArhiv from './pages/GrafikArhiv';
import NekCommand from './pages/NekCommand';
import GrafikNow from './pages/GrafikNow';
// import SendOff from './pages/SendOff';

const errorMsg = '404';

const router = createBrowserRouter([
  {
      path: "/",
      element: <Layout />,
      errorElement: <NoPage error={errorMsg}/>,
      children: [
          { index: true, element: <Home /> },
          // {
          //   path: "/send-off",
          //   element: <SendOff />,
          //   errorElement: <NoPage/>,
          // },
          {
            path: "/planovi_vidkluchenna",
            element: <Grafik type={0} />, // 0 - для планових
            errorElement: <NoPage/>,
          },
          {
            path: "/arhiv_planovi_vidkluchenna",
            element: <Grafik type={2} />, // 2 - для архіву планових
            errorElement: <NoPage/>,
          },
          {
            path: "/avarijni_vidkluchenna_realtime",
            element: <GrafikNow />,
            errorElement: <NoPage/>,
          },
          {
            path: "/avarijni_vidkluchenna",
            element: <Grafik type={1} />, // 1 - для архіву аварійних
            errorElement: <NoPage/>,
          },
          {
            path: "/schedule_queues",
            element: <GroupPage />,
            errorElement: <NoPage/>,
          },
          {
            path: "/discrepancy/:id",
            element: <Discrepancy />,
            errorElement: <NoPage/>,
          },
          {
            path: "/nek_command",
            element: <NekCommand />,
            errorElement: <NoPage/>,
          },  
    ], 
  },
  {
    path: "/healthcheck",
    element: <OkeyHttp />,
  }
]);

function App() {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;