import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
// import * as dayjs from 'dayjs';
import { getCityList, getOtgList, getStreetList, getactualPodiys } from '../Axios/searchoff';
import Table from 'react-bootstrap/Table';
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaginationLoe from './PaginationLoe';
import { defaultOptions, selectOptions } from '../utils/search';
import { formatUTC } from '../utils/utils';


const SearchOff = ({type}) => {

  const typeOptions = [
    {value: '', label: 'Всі'},
    {value: '0', label: 'Планові відключення'},
    {value: '1', label: 'Аварійні відключення'},
    {value: '2', label: 'Інші'}
  ]
  const [isCustom, setIsCustom] = useState(false); // стейт для визначення чи є кастомний фільтр

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [selectedOtg, setSelectedOtg] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const [cityOptions, setCityOptions] = useState([]);
  const [otgOptions, setOtgOptions] = useState([]);
  const [streetOptions, setStreetOptions] = useState([]);

  const [podiysList, setPodiysList] = useState([]);
  const [border, setBorder] = useState({otg: '#C4CBD2', city: '#C4CBD2'});
  const [color, setColor] = useState({otg:'hsl(0, 0%, 50%)', city: 'hsl(0, 0%, 50%)'});

  const formRef = useRef(null);
  const citySelect = useRef(null);
  const streetSelect = useRef(null);
  const otgSelect = useRef(null);
  const typeSelect = useRef(null);

  const tableOff = useRef(null);

  const [page, setPage] = useState(1);
  const [totalItems, setTotal] = useState(1);
  
  const [noOff, setNoOff] = useState('');

  useEffect(()=>{
    selectOptions(getCityList, setCityOptions);
    selectOptions(getOtgList, setOtgOptions);
  },[]);

  useEffect(()=>{
    if(selectedOtg !== null){
      selectOptions(getCityList, setCityOptions, selectedOtg.value);
    }
    if(selectedCity !== null){
      selectOptions(getStreetList, setStreetOptions, selectedCity.value);
    }
  },[selectedOtg, selectedCity])

  function alertClolors(type) {
    setBorder({...border, [type]: '#e74c3c'});
    setColor({...color, [type]:'#e74c3c'});
    setTimeout(() => {
      setBorder({...border, [type]:'#C4CBD2'});
      setColor({...color, [type]:'hsl(0, 0%, 50%)'});
    }, 4000);
  }
  
  const handleChangePage = (currentPage)=> {
    formRef.current.scrollIntoView({behavior: 'smooth'})
    setPage(currentPage);
    getactualPodiys(selectedOtg?.value || '', selectedCity?.value || '', selectedStreet?.value || '', selectedType?.value || '', isCustom, currentPage).then(response=>{
      const data = response['hydra:member'];
      setTotal(response['hydra:totalItems'])
      setPodiysList(data);
    })
  }

  function handleSubmit(e) {
    e.preventDefault();
    setPage(1);

    // відправка запиту в разі якщо дані заповнено
    if ((selectedOtg !== null && selectedCity !== null) || selectedCity !== null) {
      let сustomPodiys;
      //об'єднати тип інші в оперативні + всі з графіків -- відправка кастомних фільтрів
      if (selectedType !== null && selectedType?.value === '2') {
        сustomPodiys = true;
        setIsCustom(сustomPodiys);
      }

      getactualPodiys(selectedOtg?.value || '', selectedCity?.value || '', selectedStreet?.value || '', type || selectedType?.value || '', сustomPodiys, 1).then(response=>{
        const data = response['hydra:member'];
        setTotal(response['hydra:totalItems']);
        setPodiysList(data);
        if(data.length === 0) {
          toast.error('Вимкнень не знайдено 💡', {
            position: toast.POSITION.TOP_LEFT
         });
         setNoOff('За обраним Вами населеним пунктом/вулицею/ОТГ відключень електроенергії не зафіксовано.');
         clearData();
        }
      }).catch((err)=> {
        toast.error(`Помилка обробки даних ${err.message}`, {
            position: toast.POSITION.TOP_LEFT
        });
        clearData();
      })
    }

    // відстеження незаповнених даних
    if (selectedOtg === null && selectedCity === null) {
      toast.error(`Необхідно заповнити поля: «Населений пункт» або «ОТГ»`, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000,
      });
      setBorder({otg:'#e74c3c', city: '#e74c3c'});
      setColor({otg:'#e74c3c', city:'#e74c3c'});
      setTimeout(() => {
        setBorder({otg:'#C4CBD2', city:'#C4CBD2'});
        setColor({otg:'hsl(0, 0%, 50%)', city:'hsl(0, 0%, 50%)'});
      }, 4000);
    }
    else if (selectedCity === null && selectedOtg !== null) {
      toast.error(`Заповніть поле «Населений пункт»`, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000,
      });
      alertClolors('city');
    }

    // else if (selectedOtg === null && selectedCity !== null) {
    //   toast.error(`Заповніть поле «ОТГ»`, {
    //     position: toast.POSITION.TOP_LEFT,
    //     autoClose: 3000,
    //   });
    //   alertClolors('otg');
    // }

  }
  
  function clearData(){
    otgSelect.current.clearValue();
    citySelect.current.clearValue(); 
    streetSelect.current.clearValue();
    !type && typeSelect.current.clearValue();
    selectOptions(getCityList, setCityOptions);
    if(selectedCity !== null){
      selectOptions(getCityList, setCityOptions);
    }
  }

  const dateOptions = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  
  const timeOptions = {
    timeZone: 'UTC',
    hour: 'numeric', 
    minute: 'numeric',
  };

  return (
      <div className="search-off">
        {/* <h2 className='power-off__title'>
          {title}
        </h2> */}
        <form action="submit" onSubmit={handleSubmit} ref={formRef} data-aos="fade-up">
          <label htmlFor="otg">Об'єднана територіальна громада</label>
          <Select
            cacheOptions
            ref={otgSelect} 
            id={'otg'}
            placeholder={'Оберіть ОТГ'} 
            onChange={setSelectedOtg} 
            options={otgOptions}
            noOptionsMessage={() => 'Адреси не знайдено'} 
            loadingMessage={() => 'Завантаження...'}
            onSelectResetsInput = {false}
            onMenuOpen={clearData}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: `1px solid ${border.otg}`,
                width: '400px',
                display: 'flex',
                height: '58px',
                borderRadius: '4px',
                background: 'white',
                paddingLeft: '10px',
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: color.otg
              })
            }}
            onFocus={()=>{setBorder({...border, otg: '#C4CBD2'}); setColor({...color, otg: 'hsl(0, 0%, 50%)'})}}
            />
         <label htmlFor="city">Населений пункт</label>
          <Select
            cacheOptions
            ref={citySelect} 
            id={'city'}
            placeholder={'Оберіть ваш населений пункт'} 
            onChange={setSelectedCity} 
            defaultOptions={defaultOptions}
            options={cityOptions}
            noOptionsMessage={() => 'Адреси не знайдено'} 
            loadingMessage={() => 'Завантаження...'}
            onSelectResetsInput = {false}
            onMenuOpen={() => {
              citySelect.current.clearValue(); 
              streetSelect.current.clearValue();
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: `1px solid ${border.city}`,
                width: '400px',
                display: 'flex',
                height: '58px',
                borderRadius: '4px',
                background: 'white',
                paddingLeft: '10px',
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: color.city
              })
            }}
            onFocus={()=>{setBorder({...border, city: '#C4CBD2'}); setColor({...color, city: 'hsl(0, 0%, 50%)'})}}
            />
         <label htmlFor="street">Вулиця</label>
         <Select 
            cacheOptions
            ref={streetSelect}
            id={'street'}
            placeholder={(selectedCity === null) ? 'Заповніть поле "Населений пункт"' : "Оберіть вашу вулицю"} 
            onChange={setSelectedStreet} 
            options={streetOptions}
            noOptionsMessage={() => 'Адреси не знайдено'} 
            loadingMessage={() => 'Завантаження...'}
            isDisabled={selectedCity === null}
            onMenuOpen={()=>streetSelect.current.clearValue()}
          />
          {!type && // якщо тип не заданий явно з parent
          <>
          <label htmlFor="street">Тип відключення</label>
          <Select 
            cacheOptions
            ref={typeSelect}
            id={'type'}
            placeholder={(selectedCity === null) ? 'Заповніть поле "Населений пункт"' : "Обрати тип відключення"} 
            onChange={setSelectedType} 
            options={typeOptions}
            loadingMessage={() => 'Завантаження...'}
            isDisabled={selectedCity === null}
            onMenuOpen={()=>typeSelect.current.clearValue()}
          /></>}           
         <button className='power-btn'>Знайти</button>
         <ToastContainer/> 
       </form>
        {podiysList.length > 0 ?
        <>
          <div className="arrow-inner">	
            <div className="scroll-left" onClick={()=>{tableOff.current.scrollLeft -= 120;}}>&#8592;</div> 
            <div className="scroll-right" onClick={()=>{tableOff.current.scrollLeft += 120;}}>&#8594;</div>
          </div>
        <div className="search-off__table" ref={tableOff}>
          <Table striped bordered hover>
          <thead>
            <tr>
              <th>ОТГ</th>
              <th>Населений пункт</th>
              <th>Вулиця</th>
              <th>Будинок</th>
              <th style={{minWidth: '195px'}}>Тип відключення</th>
              <th style={{maxWidth: '160px'}}>Час відключення</th>
              <th style={{maxWidth: '160px'}}>Очікуваний час ввімкнення</th>
            </tr>
          </thead>
          <tbody>
            {podiysList.map(podiy=>{
              return <tr key={podiy['@id']}>
                        <td>{podiy.otg.name}</td>
                        <td style={{textAlign: 'center'}}>{podiy.city.name}</td>
                        <td>{podiy.street.name}</td>
                        <td style={{maxWidth: '350px', wordWrap: 'break-word'}}>{podiy.buildingNames}</td>
                        <td style={{textAlign: 'center'}}>
                          {podiy.switchSubReason > 0 ? 
                            <>{podiy.switchSubReason === 1 && 'Графік аварійних відключень'}
                              {podiy.switchSubReason === 2 && 'Графік погодинних відключень'}
                              {podiy.switchSubReason === 3 && 'Спеціальний графік аварійних відключень'}
                            </> 
                          : <>{podiy.typeOff === 0 && 'Планове відключення'}
                              {podiy.typeOff === 1 && 'Аварійне відключення'}
                              {podiy.typeOff === 2 && 'Оперативні перемикання'}
                            </>
                          }
                        </td>
                        <td style={{textAlign: 'center'}}>{formatUTC(podiy.dateEvent, dateOptions, timeOptions)}</td>
                        <td style={{textAlign: 'center'}}>{formatUTC(podiy.datePlanIn, dateOptions, timeOptions)}</td>
                    </tr>
            })}
          </tbody>
          </Table>
          </div>
               <PaginationLoe
               changePage={handleChangePage}
               currentPage={page}
               total={totalItems}
           /> 
        </> 
        : <p className="off-msg"><strong>{noOff}</strong></p>
      }
      </div>
  )
}

export default SearchOff