import { useEffect, useState } from "react";
import { getGraficOptions } from "../Axios/searchoff";
// import { formatUTC } from "../utils/utils";
import DOMPurify from 'dompurify'

const NekCommand = () => {

    const [comand, setComand] = useState(false);
    const [, setErr] = useState(false);

    useEffect(()=>{
        window.scrollTo(0,0);
      }, [])

    useEffect(()=>{
        let active = false;

        getGraficOptions( 'pw_gpv_nek_comand').then((response)=>{
            const [comand] = response['hydra:member'];
            console.log(comand);
            if(!active) {
                setComand(comand);
            }
        }).catch((err) => {
            console.log(err);
            setErr(err);
            setComand("— помилка отримання команд — " + err.message);
        })

        return () => {
            active = true;
        };
    }, [])  

    // const dateOptions = {
    //     // timeZone: 'UTC',
    //     year: 'numeric',
    //     month: 'numeric',
    //     day: 'numeric',
    //   };
      
    //   const timeOptions = {
    //     // timeZone: 'UTC',
    //     hour: 'numeric', 
    //     minute: 'numeric',
    //   };


    return (
        <section className="power-off">
            <div className="container">
            <div className="content" data-aos="fade-up">
                {/* <h2 className="power-off__title">
                    Актуалізовану інформацію щодо годин включення/відключення електроенергії 
                    станом на {err ? <span className='red'>{err}</span> : comand && formatUTC(new Date(), dateOptions, timeOptions) + ' наведено нижче:'}</h2> */}
                {/* Відображення команд НЕК */}
                {comand && 
                <div className="group-commands" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(comand.data)}}></div>}
            </div>
            </div>
        </section> 
    )
}

export default NekCommand