import { useEffect } from "react";
import SearchGrafikAvariy from "../components/SearchGrafikAvariy";
import { getHistoryPlan, getplanedPodiys } from "../Axios/searchoff";
import { getHistory } from "../Axios/searchoff";
import SearchGrafikPlanned from "../components/SearchGrafikPlanned";
import { registerLocale } from "react-datepicker";
import uk from "date-fns/locale/uk";

registerLocale("uk", uk);

const Grafik = ({type}) => {

    useEffect(()=>{
        window.scrollTo(0,0);
      }, [])

    return (
        <section className="power-off">
            <div className="container">
                {/* type === 0 - планове (1 - архів аварійних)  2 - архів планових */}
                {type === 0 && (
                    <div className="content" data-aos="fade-up">
                        <SearchGrafikPlanned getPodiys = {getplanedPodiys} />
                    </div>
                )}
                {type === 1 && (
                    <div className="content" data-aos="fade-up">
                        <SearchGrafikAvariy getPodiys = {getHistory} type = {type} />
                    </div>
                )}
                {type === 2 && (
                    <div className="content" data-aos="fade-up">
                        <SearchGrafikAvariy getPodiys = {getHistoryPlan} type = {type} />
                    </div>
                )}
            </div>
        </section> 
    )
}

export default Grafik